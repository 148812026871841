@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/icons";

.advanced-search {
  width: 100%;

  &__button {
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-family: "Trasandina W03 Medium Italic", sans-serif;

    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1rem;
      font-family: bogestra-iconfont;
      color: colors.$primary;
      content: icons.$icon-chevron-down;
      margin-left: 0.5rem;
      transition: transform 0.5s;
    }

    &.is-active::after {
      transform: translateY(calc(0.125rem - 50%)) rotateZ(180deg);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__content-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  &__content {
    padding: 0.5rem 0;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__via {
    width: calc(5 / 12 * 100%);

    @include breakpoints.down(lg) {
      width: 50%;
    }

    @include breakpoints.down(md) {
      width: 100%;
    }

    padding: 0.75rem 3.5rem 0.75rem 0;
  }

  &__col {
    width: 25%;

    &--large {
      width: 50%;
    }

    @include breakpoints.down(lg) {
      width: calc(1 / 3 * 100%);
    }

    @include breakpoints.down(md) {
      width: 50%;
    }

    @include breakpoints.down(sm) {
      width: 100%;
    }

    padding: 0.75rem 0;
  }

  &__headline {
    padding-bottom: 0.5rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
  }

  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(100% / 4);

    .checkbox {
      width: calc(1 / 3 * 100%);

      @include breakpoints.down(lg) {
        width: 50%;
      }
    }
  }

  &.is-active {
    .advanced-search__button::after {
      transform: translateY(calc(0.125rem - 50%)) rotateZ(180deg);
    }
  }
}
