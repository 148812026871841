@use "sass:color";
@use "definitions/colors";

.cookie-consent {
  margin: 1rem;
  padding: 1rem;
  background-color: colors.$accent-light;

  &__text {
    text-align: center;
    padding-bottom: 1rem;

    a {
      color: colors.$primary;
      font-family: "Trasandina W03 Medium Italic", sans-serif;

      &:hover {
        color: colors.$secondary;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;

    button {
      width: 12rem;
      background-color: colors.$primary;
      color: colors.$white;
      padding: 0.5rem;

      &:hover {
        background-color: color.change(colors.$primary, $alpha: 0.7);
      }
    }
  }
}
