@use "definitions/breakpoints";
@use "definitions/colors";

.uploads {
  $file-icon-size: 4rem;
  $file-padding: 1rem;

  &__header {
    background-color: colors.$accent-light;
  }

  &__title {
    color: colors.$primary;
    font-size: 1.5rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    text-align: center;
  }

  .search {
    @mixin larger-label() {
      @include breakpoints.down(xl) {
        @content;
      }
    }

    @mixin placeholder-label() {
      @include breakpoints.mobile {
        @content;
      }
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem $file-icon-size + $file-padding;

    @include breakpoints.mobile {
      padding: 2rem 1rem;
    }

    &__label {
      width: 25%;

      @include larger-label {
        width: calc(1 / 3 * 100%);
      }

      @include placeholder-label {
        display: none;
      }

      color: colors.$font-light;
    }

    &__input {
      width: 75%;

      @include larger-label {
        width: calc(2 / 3 * 100%);
      }

      @include placeholder-label {
        width: 100%;
      }

      $input-inner-padding: 3rem;

      input[type="text"] {
        width: 100%;
        padding: 0.625rem 1rem;
        padding-right: $input-inner-padding;

        &::placeholder {
          color: transparent;

          @include placeholder-label {
            color: initial;
          }
        }
      }

      .icon-close:not(.is-invisible) {
        cursor: pointer;

        &:hover {
          color: colors.$secondary;
        }
      }

      & > [class^="icon-"] {
        &.is-invisible {
          opacity: 0;
        }

        $search-icon-size: 1.5rem;

        color: colors.$font-light;
        font-size: $search-icon-size;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: calc((#{$input-inner-padding} - #{$search-icon-size}) / 2);
        transition: opacity 0.5s;
      }
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
  }

  &__file {
    width: 50%;

    @include breakpoints.mobile {
      width: 100%;
    }

    padding: $file-padding;

    &.is-invisible {
      display: none;
    }

    .file {
      display: flex;

      &__icon {
        flex-basis: $file-icon-size;
        color: colors.$font-light;
        padding: 1rem;
      }

      &__content {
        flex: 1;
      }

      &__date {
        color: colors.$secondary;
      }

      &__title {
        font-size: 1.5rem;
        font-family: "Trasandina W03 Medium Italic", sans-serif;
        line-height: 1.2;
      }

      &__name {
        color: colors.$primary;
        font-family: "Trasandina W03 Medium Italic", sans-serif;
      }
    }
  }
}
