$icomoon-font-family: "bogestra-iconfont" !default;
$icomoon-font-path: "fonts" !default;

$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-chevron-down: "\e904";
$icon-chevron-left: "\e905";
$icon-chevron-right: "\e906";
$icon-chevron-up: "\e907";
$icon-switch: "\e908";
$icon-close: "\e909";
$icon-menu: "\e90a";
$icon-search: "\e90b";
$icon-radio-on: "\e90c";
$icon-radio-off: "\e90d";
$icon-waypoint: "\e90e";
$icon-clock: "\e90f";
$icon-route: "\e910";
$icon-route-variant: "\e911";
$icon-deviation: "\e912";
$icon-calendar: "\e913";
$icon-map: "\e914";
$icon-facebook: "\e915";
$icon-linkedin: "\e916";
$icon-twitter: "\e917";
$icon-alert: "\e918";
$icon-bus: "\e919";
$icon-metro: "\e91a";
$icon-tram-fast: "\e91b";
$icon-train: "\e91c";
$icon-ckeckbox-off: "\e91d";
$icon-ckeckbox-on: "\e91e";
$icon-tram: "\e91f";
$icon-ICE: "\e920";
$icon-walk: "\e921";
$icon-info: "\e922";
$icon-car: "\e923";
$icon-bicycle: "\e924";
$icon-ICEC: "\e925";
$icon-roller: "\e926";
$icon-Taxi: "\e927";
$icon-instagram: "\e928";
$icon-youtube: "\e929";

