@use "definitions/breakpoints";
@use "definitions/colors";

.text-with-image {
  display: flex;
  flex-wrap: wrap;

  &__column {
    width: 50%;
    padding: 1rem;

    &--image {
      @include breakpoints.mobile {
        order: 1;
      }
    }

    &--full-width {
      width: 100%;
    }
  }

  &__image {
    line-height: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;

      @include breakpoints.mobile {
        max-height: 24rem;
      }
    }
  }

  &__text {
    a {
      color: colors.$primary;
      font-family: "Trasandina W03 Medium Italic", sans-serif;

      &:hover {
        color: colors.$secondary;
      }
    }
  }

  &--highlight {
    background-color: colors.$accent-light;

    .text-with-image__headline,
    .text-with-image__sub-headline {
      color: colors.$secondary;
    }

    .text-with-image__text {
      color: colors.$primary;
    }
  }

  &--reverse {
    .text-with-image__column--image {
      order: 1;
    }
  }

  &--cols-8-4 {
    .text-with-image__column--text {
      width: calc(2 / 3 * 100%);
    }

    .text-with-image__column--image {
      width: calc(1 / 3 * 100%);
    }
  }

  &--offset {
    .text-with-image__column {
      margin-left: calc(1 / 6 * 100%);
      width: calc(2 / 3 * 100%);

      @include breakpoints.mobile {
        margin-left: 0;
      }
    }
  }

  @include breakpoints.mobile {
    .text-with-image__column {
      width: 100%;
    }
  }
}
