@use "definitions/breakpoints";
@use "definitions/colors";
@use "variables";

.route-wrapper {
  padding: 0.5rem 0;
  border-bottom: solid 0.0625rem colors.$grey;

  .route {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__duration {
      flex: 1;
    }

    &__expand {
      flex-basis: 3rem;
      display: flex;
      align-items: center;

      .icon-chevron-down {
        cursor: pointer;
        padding: 1rem;
        font-size: 1rem;
        transition: transform 0.5s;
      }

      &.is-active .icon-chevron-down {
        transform: rotateZ(180deg);
      }
    }

    &__changes-fare {
      display: flex;
      align-items: center;

      @include breakpoints.down(sm) {
        min-width: calc(100% - #{variables.$time-width});
        order: 1;
        margin-left: variables.$time-width;
      }
    }

    &__changes {
      padding-right: 1rem;
      min-width: 6rem;
    }

    &__fare {
      width: 6rem;

      @include breakpoints.down(sm) {
        width: initial;
      }
    }

    &__type {
      font-size: 1.25rem;
    }
  }
}
