@use "definitions/breakpoints";
@use "definitions/colors";

.footer {
  background-color: colors.$accent-dark;

  &__section {
    display: flex;
    flex-wrap: wrap;

    &--info {
      padding: 1rem 0.5rem;
      color: colors.$white;
      border-bottom: solid 0.25rem colors.$primary;
      align-items: center;
    }

    &--navigation {
      padding: 0.5rem;

      a {
        color: colors.$primary;

        &:hover {
          color: colors.$white;
        }
      }
    }

    @include breakpoints.mobile {
      justify-content: center;
    }
  }

  &__group {
    padding: 0.5rem;

    &--phone {
      width: 75%;
      padding: 0 0.25rem;

      /* stylelint-disable-next-line no-descending-specificity */
      a {
        font-size: 1.5rem;
        font-family: "Trasandina W03 Medium Italic", sans-serif;
        color: colors.$white;

        &:hover {
          color: colors.$primary;
        }
      }

      @include breakpoints.mobile {
        padding-top: 0.5rem;
        padding-bottom: 2rem;
      }
    }

    &--logo {
      width: 25%;
      height: 4rem;
    }

    &--navigation {
      width: 25%;
    }

    @include breakpoints.mobile {
      width: 100%;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;

    @include breakpoints.mobile {
      justify-content: center;
    }

    &--phone {
      align-items: baseline;
    }

    &--logo {
      height: 100%;
    }
  }

  &__item {
    width: auto;

    &--logo {
      height: 100%;
    }

    &--icon {
      font-size: 1.5rem;
      padding-right: 0.5rem;

      @include breakpoints.mobile {
        font-size: 2rem;
        padding: 0 0.5rem;
      }

      &.icon-instagram {
        padding-right: 0.625rem;
      }
    }

    &--phone {
      text-align: center;
      padding: 0 0.25rem;
    }
  }
}
