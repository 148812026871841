@use "sass:color";
@use "definitions/colors";
@use "definitions/loader";

.more-routes {
  justify-content: center;
  padding: 0.5rem;
  display: none;

  &.is-visible {
    display: flex;
  }

  &__loading {
    @include loader.loader(0.125rem, 1rem, colors.$white, transparent);

    display: none;
  }

  &__button {
    background-color: colors.$primary;
    color: colors.$white;
    max-width: 18rem;
    line-height: 1.6;
    margin: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: color.change(colors.$primary, $alpha: 0.7);
    }

    &.is-loading {
      .more-routes__loading {
        display: block;
      }

      .more-routes__text {
        display: none;
      }
    }
  }
}
