@use "sass:list";
@use "sass:map";

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 976px,
  xl: 1296px,
) !default;
$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1280px,
) !default;
$desktop-min-width: map.get($breakpoints, "lg");
$mobile-max-width: calc(#{$desktop-min-width} - 1px);
$sub-nav-one-col-max-width: map.get($breakpoints, "md");

@mixin up($name) {
  @media only screen and (min-width: map.get($breakpoints, $name)) {
    @content;
  }
}

@mixin down($name) {
  @media only screen and (max-width: map.get($breakpoints, $name)) {
    @content;
  }
}

@mixin between($from, $to) {
  @media only screen and (min-width: map.get($breakpoints, $from)) and (max-width: map.get($breakpoints, $to)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-max-width) {
    @content;
  }
}

@mixin loop {
  /* stylelint-disable-next-line scss/operator-no-newline-after */
  @if list.length($breakpoints) != list.length($container-max-widths) {
    @error "$breakpoints and $container-max-widths differ in length";
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    $min-width: map.get($breakpoints, $breakpoint);

    @if not $min-width {
      @error "\"#{$breakpoint}\" defined in $container-max-widths but not in $breakpoints";
    }

    @media (min-width: $min-width) {
      @content (("container-max-width": $container-max-width, "breakpoint": $breakpoint));
    }
  }
}

// used for import in js; variable names have to be updated in /Resources/TypeScript/util/layout.ts as well
:root {
  --desktop-min-width: #{$desktop-min-width};
  --mobile-max-width: #{$mobile-max-width};
  --sub-nav-one-col-max-width: #{$sub-nav-one-col-max-width};
}
