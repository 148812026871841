@use "sass:color";
@use "definitions/colors";

.search-autocomplete {
  background-color: colors.$white;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 0.75rem 0.25rem color.change(colors.$black, $alpha: 0.125);
  padding: 1rem 0;
  margin-top: 0.5rem;
  z-index: 1;

  &__item {
    padding: 0.25rem 2rem;
    cursor: pointer;

    &:hover,
    &.selected {
      &,
      a {
        color: colors.$white;
        background-color: colors.$primary;
      }
    }

    &--top-result {
      /* stylelint-disable-next-line no-descending-specificity */
      a {
        font-weight: 600;
      }
    }
  }

  &__group {
    margin-top: 1rem;
    padding: 0.25rem 2rem;
    font-weight: 600;
    background-color: colors.$accent-light;
    color: colors.$primary;
  }
}
