@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";

.actions {
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;

  @include breakpoints.down(lg) {
    flex-basis: 100%;
  }

  &__button {
    color: colors.$white;
    padding: 0.5rem;
    max-width: 24rem;
    background-color: colors.$secondary;

    &:hover {
      background-color: color.change(colors.$secondary, $alpha: 0.8);
    }
  }
}
