@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";

.form {
  $input-height: 2.25rem;
  $label-width: 8rem;
  $input-padding: 0.5rem;

  padding: 0 1rem 2rem;
  display: flex;
  align-items: center;
  flex-flow: column wrap;

  &__required-hint {
    width: 100%;

    .required {
      color: colors.$secondary;
    }
  }

  &__page {
    max-width: 48rem;
    flex-basis: 100%;
    padding-right: $label-width;

    @include breakpoints.down(sm) {
      padding-right: 0;
    }
  }

  &__headline {
    text-align: center;
    padding-left: $label-width;

    @include breakpoints.down(sm) {
      padding-left: 0;
    }
  }

  &__actions {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  &__group {
    padding-bottom: 2rem;
    display: flex;

    @include breakpoints.down(sm) {
      flex-direction: column;
    }

    .required {
      color: colors.$secondary;
    }
  }

  &__label {
    width: $label-width;
    height: $input-height;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &__button {
    color: colors.$white;
    background-color: colors.$primary;
    padding: 0.5rem;
    width: 10rem;

    &:hover {
      background-color: color.change(colors.$primary, $alpha: 0.8);
    }
  }

  &__input {
    flex-grow: 1;
    display: flex;

    input,
    textarea,
    .checkbox,
    .choices,
    .radio-group {
      flex-grow: 1;
    }

    input,
    textarea,
    .choices {
      @include breakpoints.down(sm) {
        margin: 0 calc(-1 * #{$input-padding});
      }
    }

    input,
    textarea {
      background-color: colors.$accent-light;
    }

    textarea {
      height: 12rem;
      resize: vertical;
      font-family: "Trasandina W03 Book Italic", sans-serif;
    }

    input {
      height: $input-height;

      // Fix for autocomplete background color:
      // https://stackoverflow.com/questions/2338102/override-browser-form-filling-and-input-highlighting-with-html-css
      &:-webkit-autofill {
        &,
        &:hover,
        &:focus,
        &:active {
          /* stylelint-disable-next-line property-no-vendor-prefix */
          -webkit-box-shadow: 0 0 0 $input-height colors.$accent-light inset !important;
        }
      }
    }

    .radio-group {
      display: flex;
      flex-wrap: wrap;
    }

    .checkbox {
      padding-left: $label-width;
      padding-right: 0;

      @include breakpoints.down(sm) {
        padding-left: 0;
      }

      label {
        align-items: center;
        padding-right: $input-padding;

        &::before {
          font-size: 1.5rem;
          padding-right: 0.75rem;
        }

        a {
          color: colors.$primary;

          &:hover {
            color: colors.$secondary;
          }
        }
      }
    }
  }

  &__static-text {
    padding-left: $label-width + $input-padding;
    padding-right: $input-padding;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    padding-bottom: 1rem;

    p {
      margin: 0;
    }

    @include breakpoints.down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
