@use "definitions/colors";

.navigation-dropdown {
  background-color: colors.$accent-light;
  padding: 0.5rem;
  display: flex;

  &__header {
    font-family: "Trasandina W03 Medium Italic", sans-serif;
  }

  &__groups {
    width: 25%;
  }

  &__group {
    display: inline-block;
    width: 100%;
    padding: 0.5rem;
  }

  a:hover {
    color: colors.$primary;
  }
}
