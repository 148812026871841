@use "sass:color";
@use "definitions/colors";
@use "definitions/loader";

.loader {
  display: flex;
  justify-content: center;
  padding: 1rem;

  &__circle {
    @include loader.loader(0.5rem, 4rem, color.change(colors.$black, $alpha: 0.25), colors.$primary);

    &.is-invisible {
      display: none;
    }
  }
}
