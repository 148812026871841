@use "definitions/colors";
@use "variables" as *;

.search-count {
  display: flex;
  justify-content: center;
  padding: 0 $padding-x 1rem;
  background-color: colors.$accent-light;

  &__text {
    @include make-col-search;
  }
}

.search-result-list {
  display: flex;
  padding: 1rem $padding-x;
  justify-content: center;

  &__items {
    @include make-col-search;

    display: flex;
    flex-direction: column;
  }

  &__spellcheck {
    a {
      font-family: "Trasandina W03 Medium Italic", sans-serif;
      color: colors.$primary;

      &:hover {
        color: colors.$secondary;
      }
    }
  }
}
