@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/loader";

.traffic-information {
  font-variant-numeric: tabular-nums;
  color: colors.$font-light;
  padding: 0 1.5rem;
  background-color: colors.$accent-light;

  &__content-wrapper {
    max-height: 0;
    transition: max-height 0.5s;
    overflow: hidden;
  }

  &__item {
    &:not(:last-of-type) {
      .traffic-information__content-wrapper {
        border-top: 0.0625rem solid colors.$font-light;
      }
    }
  }

  &__title {
    padding: 0.5rem;
    padding-right: 3rem;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;

    &::after {
      content: "\e904";
      font-family: bogestra-iconfont;
      text-transform: none;
      line-height: 1;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.5s;
    }

    &.is-active {
      &::after {
        transform: rotateZ(180deg) translateY(0.5rem);
      }

      & + .traffic-information__content-wrapper {
        border-top: hidden;
      }
    }
  }

  &__date {
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    padding-right: 1.5rem;
    display: inline-block;

    @include breakpoints.down(md) {
      flex-basis: 100%;
    }
  }

  &__text {
    flex-basis: 0;
    flex-grow: 1;
  }

  &__content {
    padding: 1rem;
    background-color: white;

    & > p:first-of-type {
      margin-top: 0;
    }

    & > p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  &__button {
    background-color: colors.$primary;
    color: colors.$white;
    max-width: 12rem;
    padding: 0.5rem;

    &:hover {
      background-color: color.change(colors.$primary, $alpha: 0.7);
    }
  }

  &.is-empty + .traffic-information__button-wrapper,
  &.has-error + .traffic-information__button-wrapper,
  &.is-loading + .traffic-information__button-wrapper {
    display: none;
  }

  // hide API error text because TYPO3 backend traffic information is shown
  &.has-error[data-traffic-information="unplanned"] {
    display: none;
  }

  // don't show TYPO3 backend traffic information if API is fine
  &:not(.has-error) + .news-list {
    display: none;
  }

  &--page {
    background-color: colors.$white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-grow: 1;

    .traffic-information__content {
      background-color: colors.$accent-light;
    }
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;

    .loader {
      @include loader.loader(0.5rem, 4rem, color.change(colors.$black, $alpha: 0.25), colors.$primary);

      &.is-invisible {
        display: none;
      }
    }
  }
}

.traffic-information-filters {
  padding: 0 2rem 1rem;
  background-color: colors.$accent-light;
  display: flex;
  align-items: center;
  color: colors.$font-light;
  flex-wrap: wrap;

  &__label {
    width: 5rem;
  }

  &__select {
    flex-basis: 22rem;
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;

    @include breakpoints.up(md) {
      padding-right: 1.5rem;
    }

    @include breakpoints.up(xl) {
      padding-bottom: 0;
    }
  }

  &__date {
    flex-basis: 100%;

    @include breakpoints.up(lg) {
      flex-basis: auto;
    }

    .textfield__label {
      width: 5rem;
    }
  }
}

.traffic-information-select-filter {
  flex-grow: 1;

  .choices {
    color: colors.$font-dark;

    &__inner {
      background-color: colors.$accent-light;
      z-index: 3;
    }

    &__list {
      &--dropdown {
        z-index: 2;
        max-height: 32rem;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: color.change(colors.$black, $alpha: 0.3) colors.$white;

        &::-webkit-scrollbar-track {
          background: colors.$white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: color.change(colors.$black, $alpha: 0.3);
          border-radius: 0;
          border: 1px solid;
          border-color: colors.$white;
          border-top: none;
          border-bottom: none;
        }

        &::-webkit-scrollbar {
          width: 0.5rem;
        }
      }
    }

    &::after {
      z-index: 4;
    }
  }

  &__value {
    font-size: 1rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    border-bottom: solid colors.$primary 0.0625rem;
    padding: 0 0.5rem;
    cursor: pointer;

    &.is-disabled {
      cursor: initial;
      color: colors.$font-light;
    }

    &::after {
      font-family: bogestra-iconfont !important;
      content: "\e904";
      color: colors.$primary;
      float: right;
      padding: 0 0.25rem;
      transition: transform 0.5s;
    }
  }
}
