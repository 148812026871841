@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/icons";

.image-gallery-swipe {
  --header-height: 4rem;
  --thumbs-height: 6rem;
  --thumbs-padding: 0.5rem;

  @include breakpoints.up(md) {
    --thumbs-height: 8rem;
    --thumbs-padding: 1rem;
  }

  @include breakpoints.up(lg) {
    --thumbs-height: 10rem;
  }

  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    position: relative;
    color: colors.$white;
    background-color: color.change(colors.$black, $alpha: 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: var(--header-height);
  }

  &__number {
    font-size: 0.875rem;
    color: colors.$accent-light;
  }

  &__title {
    font-size: 1.25rem;
  }

  &__close {
    position: absolute;
    width: initial;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: colors.$white;

    &::after {
      font-family: bogestra-iconfont;
      font-size: 1.5rem;
      content: icons.$icon-close;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: colors.$primary;
      }
    }
  }

  &__image {
    height: calc(100% - var(--thumbs-height) - var(--header-height));
  }

  &__thumbs {
    background-color: color.change(colors.$black, $alpha: 0.7);
    height: var(--thumbs-height);
    display: flex;
  }
}

.image-gallery-swipe-image {
  height: 100%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  padding: 0;

  &__slides {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    position: relative;

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;

      @include breakpoints.up(md) {
        max-height: calc(100% - 4rem);
        max-width: calc(100% - 8rem);
      }
    }
  }

  &__prev {
    left: 0;

    &::after {
      content: icons.$icon-chevron-left;
    }
  }

  &__next {
    right: 0;

    &::after {
      content: icons.$icon-chevron-right;
    }
  }

  &__prev,
  &__next {
    width: initial;
    color: colors.$white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-family: bogestra-iconfont;
    padding: 1rem 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    background-color: colors.$accent-dark;
    border-radius: 0;

    &.is-disabled {
      cursor: initial;
      background-color: color.change(colors.$accent-dark, $alpha: 0.5);
      color: color.change(colors.$white, $alpha: 0.3);
    }

    &:not(&.is-disabled) {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: colors.$primary;
        }
      }
    }

    @include breakpoints.up(md) {
      font-size: 2rem;
      padding: 1rem;

      &,
      &.is-disabled {
        background-color: initial;
      }
    }
  }
}

.image-gallery-swipe-thumbs {
  padding: var(--thumbs-padding);
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-sizing: border-box;

  &__slides {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  &__slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    background-color: colors.$black;

    img {
      opacity: 0.4;
      height: 100%;
      width: auto;
      cursor: pointer;
      transition: opacity 0.5s;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 0.7;
        }
      }
    }

    &.swiper-slide-thumb-active {
      img {
        opacity: 1;
      }
    }
  }

  &__scrollbar-wrapper {
    --height: calc(var(--thumbs-padding) / 4);

    position: absolute;
    bottom: calc((var(--thumbs-padding) - var(--height)) / 2);
    left: var(--thumbs-padding);
    z-index: 1;
    height: var(--height);
    width: calc(100% - var(--thumbs-padding) * 2);
  }

  &__scrollbar {
    height: 100%;
    background: color.change(colors.$font-light, $alpha: 0.5);
  }
}
