@use "definitions/aspect-ratio" as *;
@use "definitions/breakpoints";

.header-card {
  display: flex;
  position: relative;
  align-items: flex-start;

  &__image {
    @include aspect-ratio(var(--ratio));

    line-height: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @include breakpoints.mobile {
      @include aspect-ratio;

      height: 16rem;
    }

    &--align-right {
      img {
        object-position: right;
      }
    }

    &--align-left {
      img {
        object-position: left;
      }
    }

    &--desktop:not(&--mobile) {
      @include breakpoints.down(sm) {
        display: none;
      }
    }

    &--mobile:not(&--desktop) {
      display: none;

      @include breakpoints.down(sm) {
        display: initial;
      }
    }
  }

  &__content {
    $margin-y: 1.5rem;

    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 2 * #{$margin-y});
    margin: $margin-y 1rem;
    width: 25%;

    @include breakpoints.down(md) {
      width: calc(5 / 12 * 100%);
    }

    @include breakpoints.mobile {
      width: 100%;
      position: relative;
      margin: 0;
      max-height: 16rem;
    }

    &--right {
      left: auto;
      right: 0;
    }

    &--center {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
