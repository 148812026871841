@use "sass:color";
@use "definitions/colors";
@use "definitions/variables";

.route-info-wrapper {
  z-index: variables.$z-index-modal;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: color.change(colors.$black, $alpha: 0.125);
  color: colors.$white;
  display: none;
  justify-content: center;
  align-items: center;

  &.is-visible {
    display: flex;
  }

  .route-info {
    background-color: colors.$white;
    border: solid 0.0625rem colors.$primary;
    color: colors.$primary;
    max-width: 36rem;
    max-height: calc(100% - 3rem);
    margin: 0 1.5rem;
    padding: 1rem;
    overflow: auto;
    position: relative;

    &__entry {
      display: flex;

      &:not(:last-of-type) {
        padding-bottom: 1rem;
      }
    }

    &__headline {
      font-family: "Trasandina W03 Medium Italic", sans-serif;
      font-size: 1.25rem;
      padding-bottom: 1rem;
      padding-right: 1.5rem;
    }

    &__close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0.5rem;
      cursor: pointer;

      &:hover {
        color: colors.$secondary;
      }
    }

    &__icon {
      min-width: 2rem;
    }

    &__content {
      min-width: calc(100% - 2rem);

      :first-child {
        margin-top: 0;
      }
    }
  }
}
