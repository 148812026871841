@use "definitions/breakpoints";
@use "variables";

.date-time-inputs {
  display: flex;
  width: calc(1 / 3 * 100%);

  @include breakpoints.down(xl) {
    width: calc(5 / 12 * 100%);
  }

  @include breakpoints.down(lg) {
    width: 50%;
  }

  @include breakpoints.down(md) {
    width: 100%;
  }

  @include breakpoints.down(sm) {
    flex-direction: column;
  }

  &__textfields {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__radio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;

    @include breakpoints.down(sm) {
      padding-left: variables.$label-width;
      min-width: 100%;
      min-height: 0;
      flex-direction: row;
      justify-content: start;
    }
  }
}
