@use "definitions/colors";

.routes-summary {
  padding-bottom: 1.5rem;

  &:not(&--no-results) {
    border-bottom: solid 0.0625rem colors.$grey;
  }

  &__heading {
    font-size: 1.25rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    padding-bottom: 0.25rem;
  }

  &__row {
    display: flex;
  }

  &__label {
    width: 4rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
  }
}
