@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/variables";

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: colors.$white;
  position: relative;
}

.main-container {
  box-shadow: variables.$default-box-shadow;
  min-height: 100vh;
  padding-top: variables.$header-height-desktop;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @include breakpoints.mobile {
    padding-top: variables.$header-height-mobile;
  }

  &.is-expanded {
    padding-top: variables.$header-height-mobile;
  }

  z-index: variables.$z-index-content;
  transition:
    padding-top variables.$header-expand-transition-duration,
    position variables.$sidebar-transition-duration step-end;

  // used to disable scrollbar when sidebar is opened
  &.is-disabled {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
  }

  .snackbar {
    min-width: 16rem;
    max-width: 32rem;
    background-color: colors.$secondary;
    color: colors.$white;
    text-align: center;
    border-radius: 0.5rem;
    padding: 1rem 2.25rem;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    opacity: 0;
    transition: opacity 2s, transform 1s;
    box-shadow: variables.$default-box-shadow;

    @include breakpoints.down(sm) {
      min-width: initial;
      max-width: initial;
      width: calc(100% - 2rem);
    }

    &__close {
      position: absolute;
      top: 0.125rem;
      right: 0.125rem;
      padding: 0.25rem;
      width: initial;
      background-color: transparent;
      color: colors.$white;
      font-size: 1.25rem;

      &:hover {
        color: colors.$font-dark;
      }
    }

    &.is-visible {
      opacity: 1;
      transform: translate(-50%, -1rem);
    }
  }
}
