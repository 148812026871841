@use "definitions/breakpoints";

$padding-x: 1rem;

@mixin breakpoint-search-full-width() {
  @include breakpoints.down(md) {
    @content;
  }
}

@mixin make-col-search() {
  width: calc(5 / 6 * 100%);

  @include breakpoint-search-full-width {
    width: 100%;
  }
}
