$default-line-height: 1.6;
$default-box-shadow: 0 0 0.75rem 0.25rem rgba(0 0 0 / 12.5%);
$default-input-padding: 0.5rem;
$header-border-width-desktop: 0.25rem;
$header-border-width-mobile: 0.125rem;
$header-height-desktop: 6rem + $header-border-width-desktop;
$header-height-mobile: 4rem + $header-border-width-mobile;
$z-index-content: 1;
$z-index-dropdown: $z-index-content + 1;
$z-index-sidebar: $z-index-dropdown;
$z-index-header: $z-index-dropdown + 1;
$z-index-sub-navigation: 1;
$z-index-tabs: $z-index-sub-navigation + 4;
$z-index-modal: $z-index-header + 1;
$sidebar-transition-duration: 0.5s;
$header-expand-transition-duration: 0.2s;
