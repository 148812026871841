@mixin loader($width, $diameter, $color, $gap-color) {
  border: $width solid $color;
  border-top: $width solid $gap-color;
  border-radius: 50%;
  width: $diameter;
  height: $diameter;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
