@use "definitions/colors";

.container.no-rendering {
  background-color: colors.$secondary;
  font-weight: 600;
  font-size: 1rem;
  color: colors.$white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
