@use "definitions/colors";

.news-list {
  flex-grow: 1;
  background-color: colors.$white;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

  &__header {
    padding: 0 1rem;
  }
}
