// missing ratio argument means free ratio based on content
@mixin aspect-ratio($ratio: false) {
  @if $ratio {
    height: 0;
    padding-top: calc(1 / #{$ratio} * 100%);

    & > * {
      position: absolute;
    }
  } @else {
    height: auto;
    padding-top: 0;

    & > * {
      position: relative;
    }
  }

  overflow: hidden;
  position: relative;
  width: 100%;

  & > * {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
