@use "definitions/colors";

.teaser {
  @mixin overflow-overlay-background($color) {
    background: linear-gradient(0deg, rgba($color, 1) 0%, rgba($color, 0) 100%);
  }

  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    padding-bottom: 1rem;

    .content-header {
      &__main {
        font-size: 1.25rem;
        line-height: 1.2;
        color: colors.$font-dark;
      }

      &__sub {
        color: colors.$font-light;
      }
    }
  }

  &__viewport {
    overflow: hidden;
    position: relative;

    p {
      margin: 0;
    }
  }

  &__text {
    position: absolute;
  }

  &__placeholder {
    visibility: hidden;
  }

  &__link {
    a {
      color: colors.$primary;
      font-family: "Trasandina W03 Medium Italic", sans-serif;

      &::before {
        content: "[";
      }

      &::after {
        content: "]";
      }

      &:hover {
        color: colors.$secondary;
      }
    }
  }

  &--blue {
    &,
    .teaser__subtitle,
    .teaser__title a,
    .teaser__link a {
      color: colors.$white;
    }

    .teaser__title {
      .content-header {
        &__main,
        &__sub {
          color: colors.$white;
        }
      }
    }

    .teaser__link a:hover {
      color: colors.$accent-light;
    }

    .teaser__text.is-overflown {
      &::after {
        @include overflow-overlay-background(colors.$primary);
      }
    }
  }
}
