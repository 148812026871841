@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";

.news-carousel {
  @mixin hide-navigation {
    @include breakpoints.down(md) {
      @content;
    }
  }

  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  align-items: center;

  @include hide-navigation {
    padding: 0.5rem 0;
  }

  &__footer {
    width: 100%;
    padding: 1rem;
  }

  &__button-wrapper {
    display: flex;
  }

  &__button {
    margin: 0 calc(100% / 3);
    background-color: colors.$primary;
    color: colors.$white;
    padding: 0.5rem;

    &:hover {
      background-color: color.change(colors.$primary, $alpha: 0.7);
    }
  }

  &__items-wrapper {
    width: 100%;
    flex: 1;
    overflow: hidden;
    margin: 0 1rem;
    position: relative;

    @include hide-navigation {
      flex-basis: 100%;
      margin: 0;
      padding: 0 0.5rem;
    }
  }

  &__items {
    position: relative;
    display: flex;
  }

  // Adding padding to flex item seems to cause problems with swiper,
  // so item-wrapper acts as flex child and item adds padding
  &__item-wrapper {
    width: 100%;
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);

    @include breakpoints.down(xl) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include hide-navigation {
      flex-basis: calc(2 / 3 * 100%);
      max-width: calc(2 / 3 * 100%);
    }

    @include breakpoints.down(sm) {
      flex-basis: calc(11 / 12 * 100%);
      max-width: calc(11 / 12 * 100%);
    }
  }

  &__item {
    padding: 1rem;
    height: 100%;

    @include hide-navigation {
      padding: 1rem 0.5rem;
    }
  }

  &__navigation {
    width: auto;

    @include hide-navigation {
      display: none;
    }

    cursor: pointer;

    [class^="icon-"] {
      background-color: colors.$primary;
      color: colors.$white;
      border-radius: 50%;
      padding: 0.25rem;
      font-size: 1.25rem;

      &:hover {
        background-color: color.change(colors.$primary, $alpha: 0.7);
      }
    }

    &.is-disabled {
      cursor: auto;
      pointer-events: none;

      [class^="icon-"] {
        background-color: colors.$accent-light;
      }
    }
  }

  &__pagination {
    width: 100%;
    padding-bottom: 0.5rem;
    text-align: center;
  }

  &__bullet {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 1rem;
    padding: 0.5rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      transform: translate(-50%, -50%);
      background-color: colors.$accent-light;
      border-radius: 50%;
    }

    &.is-active {
      &::after {
        background-color: colors.$accent-dark;
      }
    }

    &:not(&.is-active) {
      cursor: pointer;
    }
  }
}
