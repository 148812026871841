@use "sass:color";
@use "definitions/colors";
@use "definitions/icons";
@use "definitions/variables";

.image-gallery {
  padding: 0.5rem;
  background-color: colors.$accent-light;
  width: 100%;

  &__preview {
    height: 14rem;
    width: 100%;
    overflow: initial;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
    padding: 0;
  }

  &__slides {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
  }

  &__slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
      transition: transform 1s;
    }

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      font-family: bogestra-iconfont;
      font-size: 4rem;
      content: icons.$icon-search;
      color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          background-color: color.change(colors.$black, $alpha: 0.2);
          color: colors.$white;
        }

        img {
          transform: scale(1.25);
        }
      }
    }
  }

  &__next {
    right: -0.5rem;

    &::after {
      content: icons.$icon-chevron-right;
    }
  }

  &__prev {
    left: -0.5rem;

    &::after {
      content: icons.$icon-chevron-left;
    }
  }

  &__prev,
  &__next {
    height: 3rem;
    padding: 0;
    color: colors.$white;
    background-color: colors.$white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: initial;
    border-radius: 0;
    font-family: bogestra-iconfont;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;

    &::after {
      padding: 1rem 0.5rem;
      background-color: colors.$accent-dark;
    }

    &.is-disabled {
      cursor: initial;

      &::after {
        background-color: color.change(colors.$accent-dark, $alpha: 0.4);
      }
    }

    &:not(&.is-disabled) {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          &::after {
            background-color: color.change(colors.$accent-dark, $alpha: 0.8);
          }
        }
      }
    }
  }

  &__scrollbar-wrapper {
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;
    z-index: 1;
    height: 0.375rem;
    width: calc(100% - 0.5rem);
  }

  &__scrollbar {
    height: 100%;
    background: color.change(colors.$black, $alpha: 0.5);
  }

  &__modal {
    z-index: variables.$z-index-modal;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: color.change(colors.$black, $alpha: 0.9);
    color: colors.$white;
    display: none;

    &.is-visible {
      display: initial;
    }
  }
}
