@use "definitions/breakpoints";
@use "definitions/colors";

.card-gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;

  &__item {
    width: 25%;

    @include breakpoints.down(xl) {
      width: calc(1 / 3 * 100%);
    }

    @include breakpoints.down(md) {
      width: 50%;
    }

    @include breakpoints.down(sm) {
      width: 100%;
    }

    padding: 0.5rem;
  }

  &__image {
    background-color: colors.$accent-light;
    width: 100%;
    height: 100%;
  }
}
