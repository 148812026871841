@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/variables";

$number-of-tabs: 3;
$headline-padding: 1rem;
$headline-font-size: 1rem;
$tabs-height: 3rem;

.tab-link {
  width: calc(12 / $number-of-tabs * 100%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$white;
  background-color: colors.$accent-dark;
  text-transform: uppercase;
  cursor: pointer;

  .icon-close {
    visibility: hidden;
    padding: 0.5rem;
    position: absolute;
    right: 1rem;

    &:hover {
      color: colors.$secondary;
    }
  }

  &:not(:first-of-type) {
    border-left: solid 0.125rem color.change(colors.$accent-light, $alpha: 0.2);
  }

  &.is-active,
  &.is-active + .tab-link {
    border-left-color: transparent;
  }

  &:not(.is-active):hover {
    color: colors.$primary;
    background-color: color.change(colors.$accent-dark, $alpha: 0.9);
  }

  &.is-active {
    color: colors.$primary;
    background-color: colors.$accent-light;

    &:not(.is-disabled) {
      .icon-close {
        visibility: visible;
      }
    }
  }

  &.is-disabled {
    color: colors.$font-light;
    pointer-events: none;
  }

  &__title {
    padding-left: 0.5rem;
    font-size: $headline-font-size;
  }

  &__icon {
    font-size: 1.5rem;
    text-align: center;
  }

  @include breakpoints.down(lg) {
    .icon-close {
      right: 0.375rem;
    }
  }

  @include breakpoints.down(sm) {
    justify-content: initial;
    padding-left: 0.5rem;

    .icon-close {
      right: 0;
    }

    &__title {
      font-size: 0.875rem;
      padding-left: 0.375rem;
    }

    &__icon {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 450px) {
    justify-content: initial;
    padding-left: 0.5rem;

    &__title {
      font-size: 0.75rem;
      padding-left: 0.25rem;
    }

    &__icon {
      font-size: 0.875rem;
    }
  }

  @media only screen and (max-width: 380px) {
    &__icon {
      display: none;
    }

    &__title {
      padding-left: 0;
    }
  }
}

.tabs {
  z-index: variables.$z-index-tabs;

  &__links {
    display: flex;
    height: $tabs-height;
    position: relative;
    z-index: variables.$z-index-tabs + 1;
  }

  &__content {
    display: none;
    padding-top: 0.5rem;
    background-color: colors.$accent-light;
    position: absolute;
    width: 100%;
    box-shadow: variables.$default-box-shadow;
    max-height: calc(100% - #{$tabs-height});
    top: 0;
    margin-top: $tabs-height;

    &--scroll {
      overflow-y: auto;
    }

    &.is-visible {
      display: initial;
    }

    // use pseudo-element instead of padding because of
    // unresolved bug in FF: https://github.com/w3c/csswg-drafts/issues/129
    &::after {
      content: "";
      height: 0.5rem;
      width: 100%;
      display: block;
    }
  }
}
