@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/variables";

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.sidebars {
  position: absolute;
  top: variables.$header-height-mobile;
  height: calc(100% - #{variables.$header-height-mobile});
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: variables.$z-index-sidebar;
  pointer-events: none;

  @include breakpoints.desktop {
    top: variables.$header-height-desktop;
    height: calc(100% - #{variables.$header-height-desktop});
  }
}

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0 0 0 / 30%) colors.$accent-light;

  &::-webkit-scrollbar-track {
    background: colors.$accent-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0 0 0 / 30%);
    border-radius: 0;
    border: 1px solid;
    border-color: colors.$accent-light;
    border-top: none;
    border-bottom: none;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  pointer-events: initial;
  position: absolute;
  overflow-y: auto;
  visibility: hidden;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: colors.$accent-light;
  font-size: 1.125rem;
  transition:
    top variables.$header-expand-transition-duration,
    opacity variables.$sidebar-transition-duration,
    visibility variables.$sidebar-transition-duration step-end;

  &__content {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }

  &__item-arrow {
    color: colors.$primary;
  }

  &__item-title {
    flex: 1;
  }

  &__item {
    $shake-duration: 1s;

    display: flex;
    padding: 0.75rem 0;
    margin: 0 1rem;

    &:hover {
      cursor: pointer;
      color: colors.$secondary;
    }

    &:not(:last-of-type) {
      border-bottom: 0.125rem solid colors.$primary;
    }

    &--bold {
      font-family: "Trasandina W03 Medium Italic", sans-serif;
    }

    &--back {
      color: colors.$primary;

      &.is-active {
        .sidebar__item-arrow,
        .sidebar__item-title {
          animation: shake $shake-duration;
        }
      }
    }

    &.is-active {
      .sidebar__item-arrow,
      .sidebar__item-title {
        animation: shake $shake-duration;
      }
    }
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity variables.$sidebar-transition-duration;
  }
}
