$extra-small: 0.5rem;
$small: 1rem;
$medium: 2rem;
$large: 4rem;
$extra-large: 8rem;

.space-before {
  &--extra-small {
    padding-top: $extra-small;
  }

  &--small {
    padding-top: $small;
  }

  &--medium {
    padding-top: $medium;
  }

  &--large {
    padding-top: $large;
  }

  &--extra-large {
    padding-top: $extra-large;
  }
}

.space-after {
  &--extra-small {
    padding-bottom: $extra-small;
  }

  &--small {
    padding-bottom: $small;
  }

  &--medium {
    padding-bottom: $medium;
  }

  &--large {
    padding-bottom: $large;
  }

  &--extra-large {
    padding-bottom: $extra-large;
  }
}
