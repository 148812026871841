@use "definitions/colors";
@use "variables" as *;

.search-form {
  background-color: colors.$accent-light;
  padding: 0.5rem 0;

  @include breakpoint-search-full-width {
    padding: 0.5rem $padding-x;
  }

  &__title {
    color: colors.$primary;
    padding: 0.5rem 0;
  }

  &__input-wrapper {
    display: flex;
    padding: 0.5rem 0;
    justify-content: center;
  }

  &__input {
    @include make-col-search;

    position: relative;

    $input-inner-padding: 3rem;

    input[type="text"] {
      width: 100%;
      padding: 0.625rem 1rem;
      padding-right: $input-inner-padding;
    }

    & > [class^="icon-"] {
      $icon-size: 1.5rem;

      color: colors.$font-light;
      font-size: $icon-size;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc((#{$input-inner-padding} - #{$icon-size}) / 2);

      input[type="submit"] {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
