@use "definitions/breakpoints";
@use "definitions/colors";

.link-grid {
  background-color: colors.$accent-light;

  &__header {
    color: colors.$primary;
    padding-top: 1rem;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
  }

  &__item-wrapper {
    width: calc(100% / 3);
    padding: 0.25rem 1rem;

    @include breakpoints.mobile {
      width: 50%;
    }

    @include breakpoints.down(md) {
      width: 100%;
    }
  }

  &__item {
    background-color: colors.$white;
    color: colors.$primary;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    a {
      color: colors.$primary;
      display: block;
      padding: 0.5rem;
      width: 100%;
    }

    &:hover,
    &.is-active {
      background-color: colors.$primary;

      a {
        color: colors.$white;
      }
    }
  }
}
