@use "sass:color";
@use "definitions/colors";
@use "variables";

$input-icon-size: 1.5rem;
$input-inner-padding: 1rem;
$arrow-height: 0.5rem;

.textfield {
  display: flex;

  &__label-wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    width: variables.$label-width;
  }

  &__icon {
    font-size: $input-icon-size;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc($input-inner-padding / 2);
    z-index: 0;

    &--error {
      @keyframes pulsate {
        50% {
          transform: scale(75%);
        }
      }

      right: calc($input-icon-size + $input-inner-padding / 2);
      display: none;
      transform-origin: 50% -175%;
      color: colors.$secondary;
      animation-name: pulsate;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &--clear {
      right: calc($input-icon-size + $input-inner-padding / 2);
      z-index: 1;
      display: none;

      &.is-visible {
        display: initial;
      }
    }
  }

  &__error {
    display: none;
    position: absolute;
    z-index: 2;
    left: 0;
    top: calc(100% - 0.125rem);

    &.is-visible {
      display: initial;
    }
  }

  &__error-message {
    color: colors.$white;
    background-color: colors.$secondary;
    padding: 0.125rem 0.5rem;
    border-radius: 0.5rem;
    margin-top: $arrow-height;
    text-align: center;
  }

  &__error-arrow {
    $width: 0.375rem;

    background-color: transparent;
    height: 0;
    width: 0;
    border-color: transparent;
    border-width: 0 $width $arrow-height;
    border-bottom-color: colors.$secondary;
    border-style: solid;
    position: absolute;
    top: 0;
    left: 1rem;
  }

  &__input {
    font-family: "Trasandina W03 Regular", sans-serif;
    position: relative;
    color: colors.$font-light;
    flex-basis: 0;
    background-color: colors.$white;
    border-radius: 0.5rem;

    input[type="text"] {
      padding-right: $input-inner-padding + $input-icon-size;
    }

    input[type="date"],
    input[type="time"],
    input[type="text"] {
      width: 100%;
      background-color: transparent;
      z-index: 1;
      position: relative;

      &.has-error {
        color: colors.$secondary;

        & ~ .textfield__icon {
          &--error {
            display: initial;
            z-index: 1;
          }
        }
      }
    }

    input[type="date"],
    input[type="time"] {
      width: 11rem;
    }

    &--grow {
      flex-grow: 1;
    }
  }

  &__autocomplete {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 0.5rem;
    background-color: colors.$white;
    z-index: 2;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 0.5rem 0.75rem 0.25rem color.change(colors.$black, $alpha: 0.125);
    padding: 0.25rem 0;

    &.is-visible {
      display: initial;
    }

    div {
      cursor: pointer;
      padding: 0 0.5rem;

      &:hover,
      &:focus {
        color: colors.$white;
        background-color: colors.$primary;
      }
    }
  }
}
