@use "definitions/colors";

.news {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    color: colors.$primary;
  }
}
