@charset "UTF-8";

@font-face {
  font-family: "Trasandina W03 Light Italic";
  src: url("/fonts/2089469/34283fbe-8db3-47b9-aade-14e9d705236d.eot?#iefix");
  src:
    url("/fonts/2089469/34283fbe-8db3-47b9-aade-14e9d705236d.eot?#iefix") format("eot"),
    url("/fonts/2089469/983ac3a2-5573-45b8-906a-975ad6b6de27.woff2") format("woff2"),
    url("/fonts/2089469/7453c5cc-1956-4c0d-9f56-1bcc1766ec76.woff") format("woff"),
    url("/fonts/2089469/4206e2b1-8af1-49cf-b821-ba140e46da69.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Medium Italic";
  src: url("/fonts/2089507/29ef8fd9-3957-440a-8fb7-3c9c9f5833dd.eot?#iefix");
  src:
    url("/fonts/2089507/29ef8fd9-3957-440a-8fb7-3c9c9f5833dd.eot?#iefix") format("eot"),
    url("/fonts/2089507/4252416b-0e1c-47eb-b56d-ceefbe244769.woff2") format("woff2"),
    url("/fonts/2089507/284f5877-731b-42ad-bd0b-3e7cd35cb0bd.woff") format("woff"),
    url("/fonts/2089507/bcbf63f6-e130-425f-9671-99994e7e9f31.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Light";
  src: url("/fonts/2089570/6a097766-4e82-4490-ac70-befc6f775700.eot?#iefix");
  src:
    url("/fonts/2089570/6a097766-4e82-4490-ac70-befc6f775700.eot?#iefix") format("eot"),
    url("/fonts/2089570/480b45be-cf76-4798-a400-fa0fd54b2473.woff2") format("woff2"),
    url("/fonts/2089570/21a87788-5f79-4bbf-ae61-6798d671c918.woff") format("woff"),
    url("/fonts/2089570/fd848d56-4023-4346-8e98-8ef369c2d203.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Thin Italic";
  src: url("/fonts/2089588/c2ac3de1-978a-4176-9910-e2e44ffec186.eot?#iefix");
  src:
    url("/fonts/2089588/c2ac3de1-978a-4176-9910-e2e44ffec186.eot?#iefix") format("eot"),
    url("/fonts/2089588/753b7a77-d5d3-42ce-8b63-f6f5ca777985.woff2") format("woff2"),
    url("/fonts/2089588/444eee3c-7cd1-41c1-99ed-83d65a4aea2e.woff") format("woff"),
    url("/fonts/2089588/ac152661-a0f2-4a3b-95d9-820eb306de70.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Ultra Italic";
  src: url("/fonts/2089596/5a1d75f2-9adb-48f5-901a-5720e8c140c6.eot?#iefix");
  src:
    url("/fonts/2089596/5a1d75f2-9adb-48f5-901a-5720e8c140c6.eot?#iefix") format("eot"),
    url("/fonts/2089596/4a43f7a5-011a-4989-b2bf-38528ecd9558.woff2") format("woff2"),
    url("/fonts/2089596/56b09400-82d1-4f8a-8a5e-d014e94ca839.woff") format("woff"),
    url("/fonts/2089596/3dc0eac8-2cbd-4a56-9720-79a39e6d5419.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Black Italic";
  src: url("/fonts/2089638/8eda7931-cf2c-4461-953a-90aecf7edb7f.eot?#iefix");
  src:
    url("/fonts/2089638/8eda7931-cf2c-4461-953a-90aecf7edb7f.eot?#iefix") format("eot"),
    url("/fonts/2089638/4a55f268-5b20-4353-a0a0-6afd41713185.woff2") format("woff2"),
    url("/fonts/2089638/3086e294-2d42-4a75-a250-56e9f73df79c.woff") format("woff"),
    url("/fonts/2089638/757a82d9-04d2-45ab-aae4-bfb55dc3707b.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Book Italic";
  src: url("/fonts/2089683/d0a657f1-cded-4594-94bb-d89eb44ce920.eot?#iefix");
  src:
    url("/fonts/2089683/d0a657f1-cded-4594-94bb-d89eb44ce920.eot?#iefix") format("eot"),
    url("/fonts/2089683/6e308c32-3e14-4bba-8559-d1c063fae8cc.woff2") format("woff2"),
    url("/fonts/2089683/8e4b8a3c-e63e-4d72-bd21-69c8c8de9dbd.woff") format("woff"),
    url("/fonts/2089683/499bea64-ffeb-4550-99db-9f5148407897.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Regular Italic";
  src: url("/fonts/2168937/f14c28c4-e882-4cff-9cbb-ce9475d43752.eot?#iefix");
  src:
    url("/fonts/2168937/f14c28c4-e882-4cff-9cbb-ce9475d43752.eot?#iefix") format("eot"),
    url("/fonts/2168937/2a479abf-5622-42dd-9acb-6e1ec7d70548.woff2") format("woff2"),
    url("/fonts/2168937/95b1df29-c60e-46df-9393-b3c5c4305c59.woff") format("woff"),
    url("/fonts/2168937/b1f7ad27-dc21-4e24-99fb-e6ce219ffd04.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Ultra";
  src: url("/fonts/2168959/7947e653-e4f5-4ad0-b70c-cd8f9e18bf90.eot?#iefix");
  src:
    url("/fonts/2168959/7947e653-e4f5-4ad0-b70c-cd8f9e18bf90.eot?#iefix") format("eot"),
    url("/fonts/2168959/11b159a0-0a67-4147-be10-ed3c46b58d12.woff2") format("woff2"),
    url("/fonts/2168959/9c41d00a-393c-4c6e-ac9c-7dfbe3f3d0da.woff") format("woff"),
    url("/fonts/2168959/70618e8c-b3b7-4d86-a0bf-c43f8a891e94.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Black";
  src: url("/fonts/2168997/0f950db3-447c-49f7-a596-50c1f6a24dc4.eot?#iefix");
  src:
    url("/fonts/2168997/0f950db3-447c-49f7-a596-50c1f6a24dc4.eot?#iefix") format("eot"),
    url("/fonts/2168997/fa4af940-a2d0-4fa5-bc30-6dbf54548662.woff2") format("woff2"),
    url("/fonts/2168997/cca5b97b-a163-4a13-a753-170112672be1.woff") format("woff"),
    url("/fonts/2168997/66b9e59f-1715-490e-b7f9-1ce1d888eb59.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 ExtraLight It";
  src: url("/fonts/2169021/e805899f-be80-4c84-a470-5e247128e447.eot?#iefix");
  src:
    url("/fonts/2169021/e805899f-be80-4c84-a470-5e247128e447.eot?#iefix") format("eot"),
    url("/fonts/2169021/770d1116-3fa2-4805-bcbc-ed67f2638d47.woff2") format("woff2"),
    url("/fonts/2169021/2fcce6d6-0a46-466d-b775-04adfab27b79.woff") format("woff"),
    url("/fonts/2169021/f1dbea03-a521-4837-83e1-2649a0a32509.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Medium";
  src: url("/fonts/2169059/74ebf626-fec3-4430-aeb0-6d9509ab4d56.eot?#iefix");
  src:
    url("/fonts/2169059/74ebf626-fec3-4430-aeb0-6d9509ab4d56.eot?#iefix") format("eot"),
    url("/fonts/2169059/2f9ec552-614f-48af-9a08-60802d0ef1f9.woff2") format("woff2"),
    url("/fonts/2169059/c73abb54-ad8f-4cf4-8c93-d4a5ba685189.woff") format("woff"),
    url("/fonts/2169059/d0f919a5-e1a8-4622-acae-930187288bd9.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 ExtraLight";
  src: url("/fonts/2169065/464a1e6d-4919-46ee-b0ef-48bcb71438e1.eot?#iefix");
  src:
    url("/fonts/2169065/464a1e6d-4919-46ee-b0ef-48bcb71438e1.eot?#iefix") format("eot"),
    url("/fonts/2169065/ea301871-90d0-4937-a6fc-32ae0b7b6373.woff2") format("woff2"),
    url("/fonts/2169065/da4133d2-b31d-4f65-a0cd-3b420ae22530.woff") format("woff"),
    url("/fonts/2169065/037517a8-e1b2-45aa-adf9-f32c976abb06.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Book";
  src: url("/fonts/2169246/8cae2010-14ba-4d3d-9906-a93121e74ef8.eot?#iefix");
  src:
    url("/fonts/2169246/8cae2010-14ba-4d3d-9906-a93121e74ef8.eot?#iefix") format("eot"),
    url("/fonts/2169246/5d04b0b8-0d21-4170-947e-63979efa3c92.woff2") format("woff2"),
    url("/fonts/2169246/528da3b1-bb29-4807-98c7-00af9bf7e1b4.woff") format("woff"),
    url("/fonts/2169246/c800c4c7-d31e-4017-aca4-f0aced562d1e.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Regular";
  src: url("/fonts/2169256/fbe05bed-4cd5-48b6-9585-320f65e03dfb.eot?#iefix");
  src:
    url("/fonts/2169256/fbe05bed-4cd5-48b6-9585-320f65e03dfb.eot?#iefix") format("eot"),
    url("/fonts/2169256/41ec309f-9318-4007-834b-e140d6d13d6d.woff2") format("woff2"),
    url("/fonts/2169256/1f278667-e550-4bb9-bbbb-808ab5bd4497.woff") format("woff"),
    url("/fonts/2169256/51ce5fe3-0fb0-4c5f-a772-1fa40b534b20.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Bold";
  src: url("/fonts/2169262/2770cffc-6b64-47fc-92f6-e34900c10191.eot?#iefix");
  src:
    url("/fonts/2169262/2770cffc-6b64-47fc-92f6-e34900c10191.eot?#iefix") format("eot"),
    url("/fonts/2169262/a22a5a39-f4f7-4348-a423-28a2a2eee545.woff2") format("woff2"),
    url("/fonts/2169262/df4a1b2d-c827-40af-a5c6-7a3e77643dc9.woff") format("woff"),
    url("/fonts/2169262/798fc5a9-44ec-49fa-83fd-085eec10f971.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Thin";
  src: url("/fonts/2169264/e3753e43-8acf-4f69-8d57-89c731f9be2b.eot?#iefix");
  src:
    url("/fonts/2169264/e3753e43-8acf-4f69-8d57-89c731f9be2b.eot?#iefix") format("eot"),
    url("/fonts/2169264/a34adcf9-6896-4777-9e1a-868b4aa9e7a9.woff2") format("woff2"),
    url("/fonts/2169264/d318d790-e037-495f-a5f4-2f329d6f3d0a.woff") format("woff"),
    url("/fonts/2169264/1de95075-7a5e-4146-8c36-0bf692831b19.ttf") format("truetype");
}

@font-face {
  font-family: "Trasandina W03 Bold Italic";
  src: url("/fonts/2169332/4db67fbd-236e-450f-9fbe-d5b057c15a7b.eot?#iefix");
  src:
    url("/fonts/2169332/4db67fbd-236e-450f-9fbe-d5b057c15a7b.eot?#iefix") format("eot"),
    url("/fonts/2169332/4ef8a9ed-5592-45da-bb58-0bf1da2e91fb.woff2") format("woff2"),
    url("/fonts/2169332/9fcb3b75-dbfb-4f71-8428-8a1181d4b435.woff") format("woff"),
    url("/fonts/2169332/bba9159f-eb5f-413a-bbd1-2af7cb6ae828.ttf") format("truetype");
}
