@use "definitions/breakpoints";
@use "definitions/colors";

.news-default-detail {
  display: flex;
  justify-content: center;

  &__content {
    width: calc(2 / 3 * 100%);

    @include breakpoints.down(xl) {
      width: calc(5 / 6 * 100%);
    }

    @include breakpoints.mobile {
      width: 100%;
    }

    padding: 1rem;
  }

  &__image {
    line-height: 1;
    padding-top: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    color: colors.$primary;
    font-size: 2rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    margin: 0;
    line-height: 1.2;
    padding: 0.25rem 0;
  }

  &__date {
    color: colors.$font-light;
  }

  &__teaser {
    font-size: 1.25rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    margin: 0;
    font-weight: 400;
  }

  &__text {
    font-size: 1.25rem;

    a {
      color: colors.$primary;
      font-family: "Trasandina W03 Medium Italic", sans-serif;

      &:hover {
        color: colors.$secondary;
      }
    }
  }
}
