@use "definitions/breakpoints";
@use "definitions/colors";
@use "variables";

.partial-route {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;

  &__change {
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    padding: 1rem 0;

    .icon-route-variant {
      font-size: 1.375rem;
    }
  }

  &__footpath {
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    padding: 1rem 0;

    .icon-walk {
      font-size: 1.375rem;
    }
  }

  &__stops {
    flex: variables.$time-stop-flex;
  }

  &__stop {
    display: flex;
  }

  &__product {
    flex: variables.$product-flex;
    position: relative;

    @include breakpoints.down(md) {
      min-width: calc(100% - #{variables.$time-width});
      margin-left: variables.$time-width;
      padding-top: 1rem;
    }
  }

  &__type-text {
    font-family: "Trasandina W03 Medium Italic", sans-serif;
  }

  &__type-icon {
    font-size: 1.25rem;
    padding-right: 0.25rem;
  }

  &__info {
    padding: 0.25rem;
    cursor: pointer;

    &:hover {
      color: colors.$secondary;
    }
  }

  &__platform {
    width: variables.$platform-width;

    div {
      min-height: 1.6rem;
    }

    @include breakpoints.down(sm) {
      min-width: calc(100% - #{variables.$time-width});
      margin-left: variables.$time-width;
      order: 1;

      &:not(&--empty) {
        padding-top: 1rem;
      }

      div {
        min-height: 0;
      }
    }
  }
}
