@use "definitions/breakpoints";
@use "definitions/colors";

.search-document {
  padding: 2rem 0;
  display: flex;

  &:not(&:last-of-type) {
    border-bottom: solid 0.0625rem colors.$background;
  }

  &__image {
    flex-basis: calc(1 / 3 * 100%);
    position: relative;
    overflow: hidden;
    height: 10rem;

    @include breakpoints.down(sm) {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: 1s transform;

      &:not(&.no-image) {
        position: absolute;
        top: 0;
        left: 0;

        &:hover {
          transform: scale(125%);
        }
      }

      &.no-image {
        padding: 0 20%;
      }
    }
  }

  &__text {
    flex-basis: calc(2 / 3 * 100%);
    padding-left: 2rem;

    @include breakpoints.down(sm) {
      flex-basis: 100%;
      padding-left: 0;
    }
  }

  &__title {
    font-family: "Trasandina W03 Bold Italic", sans-serif;

    &:hover {
      color: colors.$secondary;
    }
  }

  &__teaser {
    padding: 0.5rem 0;
    display: block;
  }

  &__link {
    color: colors.$primary;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    display: inline-flex;

    &:hover {
      color: colors.$secondary;
    }
  }

  .results-highlight {
    color: colors.$secondary;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    text-transform: uppercase;
  }
}
