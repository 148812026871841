@use "definitions/colors";

.content-header {
  margin: 0;
  font-family: "Trasandina W03 Medium Italic", sans-serif;

  &__main {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.6;
    color: colors.$primary;
  }

  &__sub {
    line-height: 1.2;
    font-size: 1rem;
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

h1.content-header {
  .content-header__main {
    font-size: 2rem;
    line-height: 1.2;
  }
}

h2.content-header {
  .content-header__main {
    font-size: 1.75rem;
  }
}

h3.content-header {
  .content-header__main {
    font-size: 1.625rem;
  }
}

h4.content-header {
  .content-header__main {
    font-size: 1.5rem;
  }
}

h5.content-header {
  .content-header__main {
    font-size: 1.375rem;
  }
}

h6.content-header {
  .content-header__main {
    font-size: 1.25rem;
  }
}
