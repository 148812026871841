@use "definitions/colors";

.header-only {
  padding: 1rem;

  &--highlight {
    background-color: colors.$accent-light;
    color: colors.$secondary;

    h1.content-header {
      &__main {
        color: colors.$secondary !important;
      }
    }
  }
}
