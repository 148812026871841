@use "definitions/aspect-ratio" as *;
@use "definitions/breakpoints";
@use "definitions/colors";

.horizontal-card-gallery {
  display: flex;
  flex-wrap: wrap;

  @mixin breakpoints-full-width-image {
    @include breakpoints.down(sm) {
      @content;
    }
  }

  @mixin breakpoints-show-wide-image() {
    @include breakpoints-full-width-image {
      @content;
    }

    @include breakpoints.between(md, xl) {
      @content;
    }
  }

  &__item {
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      padding-bottom: 0;
    }

    @include breakpoints.up(xl) {
      width: 50%;

      &:nth-last-child(2) {
        padding-bottom: 0;
      }

      &:nth-of-type(odd) {
        padding-right: 0.5rem;
      }

      &:nth-of-type(even) {
        padding-left: 0.5rem;
      }
    }
  }

  &__content-wrapper {
    width: 50%;

    @include breakpoints-full-width-image {
      width: 100%;
      text-align: center;
    }

    &--text {
      background-color: colors.$primary;
    }
  }

  &__ratio-box {
    @include aspect-ratio(var(--ratio));

    @include breakpoints-show-wide-image {
      @include aspect-ratio(var(--ratio-mobile));
    }

    &--text {
      @include breakpoints-full-width-image {
        @include aspect-ratio;
      }
    }
  }

  &__text {
    padding: 1rem;
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--square {
      @include breakpoints-show-wide-image {
        display: none;
      }
    }

    &--wide {
      display: none;

      @include breakpoints-show-wide-image {
        display: initial;
      }
    }
  }
}
