@use "definitions/breakpoints";
@use "definitions/colors";

.top-nav {
  @mixin item-padding() {
    padding: 0 0.5rem;

    @include breakpoints.down(lg) {
      padding: 0 0.375rem;
    }
  }

  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;

  @include breakpoints.up(xl) {
    font-size: 1.125rem;
  }

  &__pages-wrapper {
    // @include make-col-ready;

    flex: 1;
  }

  &__pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 1;

    @include breakpoints.mobile {
      display: none;
    }
  }

  &__page {
    @include item-padding;

    flex: 0 0 auto;
    cursor: pointer;

    &:hover {
      color: colors.$primary;
    }

    .icon-chevron-down {
      color: colors.$primary;
    }

    &.is-active {
      color: colors.$primary;
    }
  }

  &__icons-wrapper {
    flex: 0 0 auto;
  }

  &__icons {
    display: flex;
    align-items: center;
  }

  &__icon {
    @include item-padding;

    flex: 0 0 auto;
    position: relative;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        color: colors.$primary;
      }
    }

    @include breakpoints.mobile {
      font-size: 1.5rem;
      line-height: 1;
    }

    [class^="icon-"] {
      display: block;
      width: 100%;
      height: 100%;
      transition: opacity 1s;
    }

    .icon-close {
      @include item-padding;

      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
    }

    &.is-active {
      .icon-close {
        opacity: 100;
      }
    }

    &--search {
      &.is-active {
        .icon-search {
          opacity: 0;
        }
      }
    }

    &--mobile {
      @include breakpoints.desktop {
        display: none;
      }

      &.is-active {
        .icon-menu {
          opacity: 0;
        }
      }
    }
  }
}
