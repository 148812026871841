@use "sass:color";
@use "definitions/breakpoints";
@use "definitions/colors";
@use "variables" as *;

.search-facets {
  display: flex;
  background-color: colors.$accent-light;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 1rem;

  @include breakpoint-search-full-width {
    padding-left: $padding-x;
    padding-right: $padding-x;
  }

  &__select {
    width: 100%;

    @include breakpoints.up(sm) {
      display: none;
    }
  }

  &__tabs {
    @include make-col-search;

    display: none;
    justify-content: center;

    @include breakpoints.up(sm) {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__tab {
    padding: 0.5rem;
    background-color: colors.$accent-dark;
    border-radius: 0.5rem;
    width: 100%;
    color: colors.$white;
    text-align: center;

    &:hover {
      background-color: color.change(colors.$accent-dark, $alpha: 0.9);
      color: colors.$primary;
    }

    &.is-active {
      background-color: colors.$primary;

      &:hover {
        background-color: color.change(colors.$primary, $alpha: 0.8);
        color: colors.$accent-light;
      }
    }
  }
}
