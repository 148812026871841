@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/variables";

.header {
  position: fixed;
  z-index: variables.$z-index-header;
  width: 100%;
  height: variables.$header-height-desktop;
  transition: height variables.$header-expand-transition-duration;

  @mixin is-collapsed {
    height: variables.$header-height-mobile;

    &__background {
      border-bottom-width: variables.$header-border-width-mobile;
    }
  }

  &.is-collapsed {
    @include is-collapsed;
  }

  @include breakpoints.mobile {
    @include is-collapsed;
  }

  // Without a background element, dropdowns inside the header can't be hidden
  // behind the header because they would be child elements
  &__background {
    border-bottom: variables.$header-border-width-desktop solid colors.$grey;
    background-color: colors.$white;
    height: 100%;
    width: 100%;
    transition: border-bottom-width variables.$header-expand-transition-duration;
  }

  &__content-wrapper {
    padding: 0 1rem;
    height: 100%;
  }

  &__content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 25%;

    @include breakpoints.down(lg) {
      flex: 1;
    }

    @include breakpoints.mobile {
      width: auto;
    }

    a {
      display: inline-block;
      line-height: 1;
    }

    img {
      padding: 0 0.5rem;
      width: 8rem;
    }
  }

  &__navigation {
    width: 100%;
    flex: 0 0 75%;
    max-width: 75%;

    @include breakpoints.down(xl) {
      width: auto;
      flex: 0 0 auto;
      max-width: 100%;
    }
  }

  &__dropdowns {
    position: relative;
    z-index: -1;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(-100%);
    transition: transform 0.5s;

    &.is-visible {
      transform: translateY(0);
      box-shadow: 0 0.75rem 0.75rem -0.75rem rgba(0 0 0 / 12.5%);
    }
  }
}
