@use "definitions/breakpoints";
@use "definitions/colors";

.cta-image {
  display: flex;
  flex-direction: column;

  @include breakpoints.up(md) {
    height: 15rem;
    flex-direction: row;
  }

  &__col-1 {
    height: 15rem;

    @include breakpoints.up(md) {
      height: initial;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__col-2 {
    background-color: colors.$primary;
    color: colors.$white;
    padding: 1rem;
    order: -1;

    @include breakpoints.up(md) {
      order: initial;
    }
  }

  &__col-3 {
    background-color: colors.$orange;
    color: colors.$white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;

    @include breakpoints.up(md) {
      gap: 0;
    }
  }

  &__subheader {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: 1.2;

    @include breakpoints.up(md) {
      font-size: 1.25rem;
    }

    @include breakpoints.up(xl) {
      font-size: 1.5rem;
    }
  }

  &__content {
    display: flex;
  }

  &__bodytext {
    *:first-of-type {
      margin-top: 0;
    }

    *:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    max-width: 5rem;
  }

  &__header {
    margin: 0;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    @include breakpoints.up(xl) {
      font-size: 2rem;
    }
  }

  &__link {
    padding: 1rem;
    border: 0.125rem solid colors.$white;
    border-radius: 0.5rem;
    color: colors.$white;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    transition: background-color 0.5s, color 0.5s;
    text-align: center;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: colors.$white;
        color: colors.$orange;
      }
    }
  }

  &__col {
    @include breakpoints.up(md) {
      width: calc(1 / 3 * 100%);
    }
  }
}
