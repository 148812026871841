@use "sass:color";
@use "definitions/colors";

.breadcrumbs {
  @mixin scroll-indicator-background($degree) {
    background:
      linear-gradient(
        $degree,
        color.change(colors.$accent-light, $alpha: 1) 0%,
        color.change(colors.$accent-light, $alpha: 1) 70%,
        color.change(colors.$accent-light, $alpha: 0.6) 95%,
        color.change(colors.$accent-light, $alpha: 0) 100%
      );
  }

  padding: 1rem;
  background-color: colors.$accent-light;
  white-space: nowrap;
  position: relative;

  &__viewport {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: color.change(colors.$black, $alpha: 0.3) colors.$accent-light;

    &::-webkit-scrollbar-track {
      background: colors.$accent-light;
    }

    &::-webkit-scrollbar-thumb {
      background-color: color.change(colors.$black, $alpha: 0.3);
      border-radius: 0;
      border: 1px solid;
      border-color: colors.$accent-light;
      border-top: none;
      border-bottom: none;
    }

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  &__content {
    display: inline-block;
  }

  &__scroll-indicator {
    position: absolute;
    top: 0;
    height: 100%;
    width: 2rem;
    opacity: 0;
    transition: opacity 0.5s;

    &.is-visible {
      opacity: 1;
    }

    &--left {
      left: 0;

      @include scroll-indicator-background(90deg);
    }

    &--right {
      right: 0;

      @include scroll-indicator-background(270deg);
    }
  }

  &__link {
    text-size-adjust: none;
    color: colors.$primary;

    &:not(&--disabled):hover {
      text-decoration: underline;
    }

    &--disabled {
      color: colors.$font-light;
    }
  }

  &__divider {
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 50%;
    margin: 0 0.5rem;
    background-color: colors.$font-dark;
    display: inline-block;
    vertical-align: middle;
  }
}
