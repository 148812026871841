@use "definitions/breakpoints";

.stop-inputs {
  display: flex;
  width: calc(5 / 12 * 100%);

  @include breakpoints.down(lg) {
    width: 50%;
  }

  @include breakpoints.down(md) {
    width: 100%;
  }

  &__textfields {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
  }

  &__swap {
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;

    .icon-switch {
      padding: 0.5rem;
      cursor: pointer;
    }
  }
}
