@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/icons";

.image-gallery-scroll {
  padding: 1rem;

  &__header {
    border-bottom: solid colors.$primary 0.125rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
  }

  &__close {
    cursor: pointer;
    text-transform: uppercase;
    color: colors.$primary;

    &::before {
      font-family: bogestra-iconfont;
      text-transform: none;
      line-height: 1;
      padding-right: 0.25rem;
      content: icons.$icon-arrow-left;
    }

    &:hover {
      color: colors.$white;
    }
  }

  &__subheadline {
    font-family: "Trasandina W03 Book", sans-serif;
  }

  &__headline {
    color: colors.$primary;
    font-size: 2rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    line-height: 1;
    padding-bottom: 1rem;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;

    &:not(:last-of-type) {
      border-bottom: solid colors.$primary 0.125rem;
    }
  }

  &__image {
    width: 75%;
    padding-right: 2rem;
    line-height: 1;

    @include breakpoints.mobile {
      width: 100%;
      padding-right: 0;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &__text {
    width: 25%;

    @include breakpoints.mobile {
      width: 100%;
    }
  }

  &__title {
    color: colors.$primary;
    padding-bottom: 1rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
  }

  &__number {
    font-family: "Trasandina W03 Book", sans-serif;
  }
}
