$white: #fff;
$black: #000;
$grey: #ccc;
$green: #46b482;
$orange: #f7a600;
$font-dark: #00263e;
$font-light: #80939f;
$primary: #008dcd;
$secondary: #dc002d;
$accent-dark: #003778;
$accent-light: #d6eff7;
$background: #f2f4f5;
