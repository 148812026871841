@use "sass:map";
@use "breakpoints";

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@include breakpoints.loop using ($args) {
  .container {
    max-width: map.get($args, "container-max-width");
  }
}
