@use "definitions/colors";

.pagination {
  display: flex;
  padding: 1rem 0;
  justify-content: space-between;
  align-items: center;

  &__pages {
    display: flex;
    align-items: center;
  }

  &__page-wrapper {
    padding: 0 0.5rem;
  }

  &__page {
    $size: 1.5rem;

    border-radius: 50%;
    height: $size;
    width: $size;
    text-align: center;
    font-size: 0.875rem;
    background-color: colors.$accent-light;
    display: block;

    a {
      color: colors.$primary;
      display: block;
    }

    &--current {
      color: colors.$white;
      background-color: colors.$secondary;
    }
  }

  &__divider-wrapper {
    padding: 0 0.25rem;
  }

  &__divider {
    $size: 0.375rem;

    border-radius: 50%;
    height: $size;
    width: $size;
    background-color: colors.$accent-light;
    display: block;
  }

  &__button {
    position: relative;

    [class^="icon-"] {
      background-color: colors.$primary;
      color: colors.$white;
      border-radius: 50%;
      padding: 0.25rem;
      font-size: 1.25rem;

      a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    &.is-disabled {
      [class^="icon-"] {
        background-color: colors.$accent-light;

        a {
          cursor: auto;
          pointer-events: none;
        }
      }
    }
  }
}
