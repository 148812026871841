@use "definitions/breakpoints";
@use "definitions/colors";
@use "definitions/variables";

.sub-navigation {
  @mixin two-columns {
    @include breakpoints.down(xl) {
      @content;
    }
  }

  @mixin one-column {
    @media (max-width: breakpoints.$sub-nav-one-col-max-width) {
      @content;
    }
  }

  $padding-column: 1.5rem;
  $padding-x-link: 1rem;
  $padding-x-link-one-column: 0.5rem;
  $padding-x-one-column: 1rem;
  $dropdown-transition-duration: 0.5s;

  display: flex;
  flex-wrap: wrap;
  background-color: colors.$accent-light;
  padding: 0 1.5rem 1.5rem;
  z-index: variables.$z-index-sub-navigation;

  @include one-column {
    padding: 0 0 1.5rem;
  }

  &__column {
    width: calc(1 / 3 * 100%);
    padding: $padding-column;

    @include two-columns {
      width: 50%;

      &--text {
        order: 1;
        width: 100%;
      }
    }

    @include one-column {
      width: 100%;
      padding: $padding-column $padding-x-one-column;

      &--text {
        order: 0;
      }

      &--image {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &--links {
        padding: $padding-column ($padding-x-one-column - $padding-x-link-one-column);
      }
    }
  }

  &__title {
    padding: 0 $padding-x-link;
    font-size: 1rem;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    pointer-events: none;

    @include one-column {
      pointer-events: all;
      border-bottom: solid colors.$primary 0.0625rem;
      padding: 0 $padding-x-link-one-column;
      cursor: pointer;

      &::after {
        font-family: bogestra-iconfont !important;
        content: "\e904";
        color: colors.$primary;
        float: right;
        padding: 0 0.25rem;
        transition: transform 0.5s;
      }

      &.is-active {
        &::after {
          transform: rotate(180deg);
        }

        & ~ .sub-navigation__links--dropdown {
          z-index: variables.$z-index-sub-navigation + 1;
          transition: z-index 0s;
        }
      }
    }
  }

  &__links {
    @include one-column {
      display: none;
    }

    &--dropdown {
      display: none;
      position: absolute;
      left: 0;
      z-index: 0;
      overflow: hidden;
      padding: 0 ($padding-x-one-column - $padding-x-link-one-column) $padding-x-one-column;
      width: 100%;
      transition: z-index step-end $dropdown-transition-duration;

      @include one-column {
        display: initial;
      }
    }
  }

  &__link {
    font-size: 1rem;
    padding: 0.5rem 1.5rem 0.5rem $padding-x-link;
    display: block;
    position: relative;

    &::after {
      font-family: bogestra-iconfont !important;
      content: "\e906";
      color: colors.$primary;
      position: absolute;
      right: 0.25rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      color: colors.$secondary;
    }

    &:not(:last-of-type) {
      border-bottom: solid colors.$primary 0.0625rem;
    }

    @include one-column {
      padding: 0.5rem;

      &::after {
        padding: 0 0.25rem;
      }

      &:hover {
        background-color: colors.$primary;
        color: colors.$white;

        &::after {
          color: colors.$white;
        }
      }
    }
  }

  &__dropdown {
    transform: translateY(-100%);
    position: relative;
    background-color: colors.$white;
    width: 100%;
    transition: transform $dropdown-transition-duration;

    &.is-visible {
      transform: translateY(0);
      box-shadow: variables.$default-box-shadow;
    }

    .sub-navigation__link::after {
      content: "";
    }
  }

  &__headline {
    color: colors.$accent-dark;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    font-size: 2rem;
    line-height: 1;
    padding-bottom: 1rem;
    margin: 0;
  }

  &__text {
    color: colors.$primary;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
    max-height: 24rem;
  }
}
