@use "definitions/colors";
@use "variables";

.route-time {
  min-width: variables.$time-width;
  font-variant-numeric: tabular-nums;
  font-family: "Trasandina W03 Medium Italic", sans-serif;

  &__delay {
    &--red {
      color: colors.$secondary;
    }

    &--green {
      color: colors.$green;
    }
  }
}
