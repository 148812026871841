@use "colors";

.js-table {
  $content-z-index: 0;

  position: relative;

  &__viewport {
    overflow-x: auto;
    position: relative;
    z-index: $content-z-index;
  }

  @mixin table-overlay($side) {
    content: "";
    top: 0;
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(to $side, rgb(255 255 255 / 0%) 0%, white 100%);
    transition: width 1s;
    z-index: $content-z-index + 1;
  }

  &__overlay-left {
    @include table-overlay(left);

    left: 0;
  }

  &__overlay-right {
    @include table-overlay(right);

    right: 0;
  }

  &__overlay-left,
  &__overlay-right {
    &.is-visible {
      width: 4rem;
    }
  }
}

table {
  $border-overlap-height: 0.5rem;
  $border-width: 0.0625rem;
  $border: solid colors.$black $border-width;
  $padding-y: 0.5rem;

  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
  margin: calc(#{$border-overlap-height} * 2) 0;

  thead {
    color: colors.$primary;
  }

  th {
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    text-align: left;
  }

  td,
  th {
    position: relative;
    padding: $padding-y 1rem;

    &:not(:last-child) {
      border-right: $border;
    }
  }

  // Overlap used only for odd first or last row so the border doesn't end with the background
  %border-overlap {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100% - #{$border-overlap-height});

    // Workaround for BOGINTRE-108
    // instead of color-accent-light use color with alpha as high as possible using this formula:
    // https://stackoverflow.com/a/6672545
    background-color: rgb(0 155 205 / 16%);
    z-index: -1;
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: colors.$accent-light;

      // Set overlap for last row if it is odd
      &:last-of-type {
        background-color: transparent;

        th,
        td {
          padding-bottom: $padding-y + $border-overlap-height;
          z-index: 1;

          &::after {
            @extend %border-overlap;

            top: 0;
          }
        }
      }
    }

    // Set overlap for first row only if there is no table header
    &:not(thead + tbody) {
      // Row can be first and last in body, but both rules don't interfere
      // because they don't set the same attributes to different values
      /* stylelint-disable-next-line no-descending-specificity, selector-list-comma-newline-after */
      tr:nth-of-type(odd) {
        &:first-of-type {
          background-color: transparent;

          th,
          td {
            padding-top: $padding-y + $border-overlap-height;
            z-index: 1;

            &::before {
              @extend %border-overlap;

              bottom: 0;
            }
          }
        }
      }
    }
  }
}
