@use "definitions/breakpoints";
@use "definitions/colors";

.timetable {
  display: flex;
  flex-wrap: wrap;
  background-color: colors.$accent-light;
  color: colors.$font-light;
  padding: 1rem 0.5rem;

  @include breakpoints.up(md) {
    padding: 1rem 1.5rem;
  }

  &__group {
    padding: 0.5rem;
  }

  &__textfields {
    min-height: 6rem;
  }
}
