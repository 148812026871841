@use "definitions/colors";

.navigation-search {
  background-color: colors.$accent-light;
  padding: 0.5rem 1.5rem 1.5rem;

  &__title {
    color: colors.$primary;
    text-align: center;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    padding: 0.5rem;
    font-size: 1.25rem;
  }

  &__input {
    $input-inner-padding: 3rem;

    position: relative;
    padding-top: 0.5rem;

    input[type="text"] {
      width: 100%;
      padding: 0.625rem 1rem;
      padding-right: $input-inner-padding;
    }

    & > [class^="icon-"] {
      $icon-size: 1.5rem;

      color: colors.$font-light;
      font-size: $icon-size;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc((#{$input-inner-padding} - #{$icon-size}) / 2);

      input[type="submit"] {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
}
