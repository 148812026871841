@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?aqg531');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?aqg531#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?aqg531') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?aqg531') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?aqg531##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-radio-on {
  &:before {
    content: $icon-radio-on; 
  }
}
.icon-radio-off {
  &:before {
    content: $icon-radio-off; 
  }
}
.icon-waypoint {
  &:before {
    content: $icon-waypoint; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-route {
  &:before {
    content: $icon-route; 
  }
}
.icon-route-variant {
  &:before {
    content: $icon-route-variant; 
  }
}
.icon-deviation {
  &:before {
    content: $icon-deviation; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-bus {
  &:before {
    content: $icon-bus; 
  }
}
.icon-metro {
  &:before {
    content: $icon-metro; 
  }
}
.icon-tram-fast {
  &:before {
    content: $icon-tram-fast; 
  }
}
.icon-train {
  &:before {
    content: $icon-train; 
  }
}
.icon-ckeckbox-off {
  &:before {
    content: $icon-ckeckbox-off; 
  }
}
.icon-ckeckbox-on {
  &:before {
    content: $icon-ckeckbox-on; 
  }
}
.icon-tram {
  &:before {
    content: $icon-tram; 
  }
}
.icon-ICE {
  &:before {
    content: $icon-ICE; 
  }
}
.icon-walk {
  &:before {
    content: $icon-walk; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-car {
  &:before {
    content: $icon-car; 
  }
}
.icon-bicycle {
  &:before {
    content: $icon-bicycle; 
  }
}
.icon-ICEC {
  &:before {
    content: $icon-ICEC; 
  }
}
.icon-roller {
  &:before {
    content: $icon-roller; 
  }
}
.icon-Taxi {
  &:before {
    content: $icon-Taxi; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}

