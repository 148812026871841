@use "colors";
@use "variables";

.choices {
  position: relative;
  cursor: pointer;

  &__inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border-bottom: solid colors.$primary 0.0625rem;
    overflow: hidden;
    background-color: colors.$white;
    position: relative;
    z-index: 2;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
  }

  &__item {
    width: 100%;
    padding: 0.25rem 0.5rem;
    position: relative;
    cursor: pointer;

    &.is-highlighted {
      background-color: colors.$primary;
      color: colors.$white;
    }

    &.is-selected {
      font-family: "Trasandina W03 Medium Italic", sans-serif;
    }
  }

  &__list {
    margin: 0;
    padding-left: 0;
    list-style: none;

    &--single {
      display: inline-block;
      width: 100%;
    }

    &--dropdown {
      @keyframes delay-box-shadow {
        from { box-shadow: variables.$default-box-shadow; }
      }

      $transition-duration: 0.5s;

      height: 0;
      z-index: 1;
      position: absolute;
      width: 100%;
      background-color: colors.$white;
      top: 100%;
      margin-top: -1px;
      overflow: hidden;
      word-break: break-all;
      will-change: height;
      transition: height $transition-duration;

      .choices__item {
        border-top: solid colors.$primary 0.0625rem;
      }

      &.is-active {
        box-shadow: variables.$default-box-shadow;
      }

      &.is-inactive {
        animation: delay-box-shadow $transition-duration step-end;
      }
    }
  }

  [hidden] {
    display: none !important;
  }

  &::after {
    font-family: bogestra-iconfont !important;
    content: "\e904";
    color: colors.$primary;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 0.25rem;
    transition: transform 0.5s;
    z-index: 2;
  }

  &.is-open {
    overflow: initial;

    &::after {
      transform: rotate(180deg) translateY(50%);
    }
  }

  &:focus {
    outline: none;
  }
}
