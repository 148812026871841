@use "definitions/colors";

.sitemap {
  padding: 0 1rem 1rem;

  &__bullet {
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 50%;
    background-color: colors.$font-dark;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.0625rem;
  }

  &__link {
    &--level-1 {
      font-size: 2rem;
      font-family: "Trasandina W03 Medium Italic", sans-serif;
      padding-top: 1rem;
    }

    &--level-2 {
      font-size: 1.5rem;
      font-family: "Trasandina W03 Regular Italic", sans-serif;
    }

    &--level-3 {
      font-size: 1.25rem;
    }

    a:hover {
      color: colors.$secondary;
    }
  }
}
