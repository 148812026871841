@use "definitions/breakpoints";
@use "definitions/colors";
@use "variables";

.route-details-wrapper {
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;

  .route-details {
    margin: 0.5rem 0;
    padding: 0 1rem;
    background-color: colors.$accent-light;

    &__shop {
      display: flex;
      justify-content: center;
      padding: 1rem;

      .button {
        background-color: transparent;
        border: 0.0625rem solid colors.$primary;
        color: colors.$primary;
        max-width: 12rem;
        font-size: 1rem;
        line-height: 1.6;

        &:hover {
          background-color: colors.$primary;
          color: colors.$accent-light;
        }
      }
    }

    &__heading {
      display: flex;
      font-family: "Trasandina W03 Medium Italic", sans-serif;
      padding: 0.5rem 0;
      border-bottom: solid 0.0625rem colors.$grey;

      @include breakpoints.down(md) {
        display: none;
      }
    }

    &__time-stop {
      flex: variables.$time-stop-flex;
      display: flex;
    }

    &__time {
      width: variables.$time-width;
    }

    &__platform {
      width: variables.$platform-width;
    }

    &__product {
      flex: variables.$product-flex;
    }
  }
}
