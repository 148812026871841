@use "definitions/breakpoints";

.news-default-list {
  padding: 0 1rem;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;

  &__items {
    flex-basis: 100%;

    @include breakpoints.mobile {
      margin: 0 -1rem;
    }
  }

  &__item {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    width: 100%;
  }

  &__image {
    width: 25%;
    line-height: 1;

    @include breakpoints.mobile {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;

      &.no-image {
        padding: 0 35%;
      }
    }
  }

  &__content {
    width: 75%;
    padding: 1rem;

    @include breakpoints.mobile {
      width: 100%;
    }

    .teaser .teaser__title .content-header .content-header__main {
      font-size: 1.25rem;
    }
  }
}
