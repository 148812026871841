@use "definitions/colors";
@use "definitions/variables";

.card {
  box-shadow: variables.$default-box-shadow;
  height: 100%;
  text-align: center;
  background-color: colors.$white;

  &__content {
    height: 100%;
    padding: 2rem;
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      &.no-image {
        object-fit: contain;
        padding: 0 35%;
      }
    }
  }

  &--blue {
    background-color: colors.$primary;
  }
}
