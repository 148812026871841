@use "definitions/breakpoints";
@use "definitions/colors";

.header-slider {
  flex: 1;
  overflow: hidden;
  position: relative;
  padding-bottom: 1rem;

  &__items {
    position: relative;
    display: flex;
  }

  &__item {
    flex-shrink: 0;

    .header-card {
      height: 100%;
      flex-flow: column nowrap;

      &__content {
        flex-grow: 1;
        flex-basis: 0;

        .content-header {
          &__main {
            font-size: 1.25rem;
          }
        }
      }
    }

    @include breakpoints.mobile {
      .card {
        padding-bottom: 2rem;
      }
    }
  }

  &__pagination {
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;

    @include breakpoints.mobile {
      position: absolute;
      bottom: 1rem;
    }
  }

  &__bullet {
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 1rem;
    padding: 0.5rem;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      transform: translate(-50%, -50%);
      background-color: colors.$accent-light;
      border-radius: 50%;
    }

    &.is-active {
      &::after {
        background-color: colors.$accent-dark;
      }
    }

    &:not(&.is-active) {
      cursor: pointer;
    }
  }
}
