@use "colors";
@use "variables";

@mixin input($content-checked, $content-unchecked) {
  font-family: "Trasandina W03 Regular", sans-serif;
  padding-right: 1rem;

  label {
    padding: 0.375rem 0;
    position: relative;
    line-height: 1.2;
    display: flex;

    &::before {
      font-family: bogestra-iconfont;
      color: colors.$primary;
      content: $content-unchecked;
      padding-right: 0.25rem;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    &:checked + label::before {
      content: $content-checked;
    }
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  background-color: colors.$background;
  overflow-y: scroll;
  margin: 0;
  display: flex;
  flex-direction: column;
  line-height: variables.$default-line-height;
  color: colors.$font-dark;

  &,
  .button,
  button,
  input {
    font-family: "Trasandina W03 Book Italic", sans-serif;
    font-weight: 400;
  }

  &,
  input,
  a {
    color: colors.$font-dark;
  }

  a {
    text-decoration: none;
  }

  input[type="text"],
  input[type="date"],
  input[type="email"],
  input[type="time"],
  textarea {
    border: hidden;
    padding: variables.$default-input-padding;
    border-radius: 0.5rem;
    outline: none;
    font-size: 1rem;
  }

  input[type="date"],
  input[type="time"] {
    font-variant-numeric: tabular-nums;

    &::-webkit-calendar-picker-indicator {
      opacity: 0;
    }
  }

  button,
  .button {
    line-height: 1.2;
    width: 100%;
    border: hidden;
    border-radius: 0.5rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    padding: 0;

    li {
      display: block;

      &::before {
        content: "\2022";
        margin-right: 0.25rem;
      }
    }
  }

  .radio {
    @include input("\e90c", "\e90d");
  }

  .checkbox {
    @include input("\e91e", "\e91d");
  }
}
