@use "definitions/colors";

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$orange;
  padding: 1rem;
  gap: 2rem;

  &__text {
    color: colors.$white;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    font-size: 1.5rem;
  }

  &__link {
    padding: 1rem;
    border: 0.125rem solid colors.$white;
    border-radius: 0.5rem;
    color: colors.$white;
    font-family: "Trasandina W03 Medium Italic", sans-serif;
    transition: background-color 0.5s, color 0.5s;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: colors.$white;
        color: colors.$orange;
      }
    }
  }
}
